import { ICampaignInputOptions } from '../../models/campaign-input-options.model';
import { AdvertiserModel } from '../../models/advertiser.model';
import { makeAutoObservable, runInAction } from 'mobx';
import { CampaignsInputOptionsApi } from '../../apis/campaign-input-options.api';
import { StrategyModel } from '../../models/strategy.model';
import { PretargetingModel } from '../../models/pretargeting.model';
import { capitalCase } from 'change-case';
import { URLSearchParams } from 'url';
import { BannerSize } from '../../models/campaign.model';
import { ISegmentation } from '../../models/campaign-segmentation.model';
import { AccountsModel } from '../../models/account.model';

export class CampaignInputOptionsStore {
	private advertisers: AdvertiserModel[] = [];
	private strategies: StrategyModel[] = [];
	private pretargetings: PretargetingModel[] = [];
	private accounts: AccountsModel[] = [];
	private key_generators: string[] = [];
	private device_types: string[] = [];
	private active_browsers: string[] = [];
	private operating_systems: string[] = [];
	private banner_sizes: BannerSize[] = [];
	private isLocalCache = false;
	private isLoading = false;
	private error: Error | null = null;
	private webapps_cid_segments: ISegmentation[] = [];
	design_score_pickers: string[] = [];
	format_score_pickers: string[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	fetch(options?: URLSearchParams): Promise<void | ICampaignInputOptions> {
		if (this.isLocalCache) {
			return Promise.resolve();
		}

		this.isLocalCache = true;
		this.setIsLoading(true);
		return CampaignsInputOptionsApi.get(options)
			.then(res => {
				runInAction(() => {
					this.isLocalCache = false;
					this.setInputOptions(res as ICampaignInputOptions);
					this.setIsLoading(false);
				});
			})
			.catch((error: Error) => {
				// TODO - log
				runInAction(() => {
					this.error = error;
				});
			});
	}

	setInputOptions(campaignInputOptions: ICampaignInputOptions): void {
		this.advertisers = campaignInputOptions.advertisers.map(advertiser => new AdvertiserModel(advertiser));
		this.strategies = campaignInputOptions.strategies.map(strategy => new StrategyModel(strategy));
		this.pretargetings = campaignInputOptions.pretargetings.map(pretargeting => new PretargetingModel(pretargeting));
		this.key_generators = campaignInputOptions.key_generators;
		this.device_types = campaignInputOptions?.device_types?.map(deviceType => capitalCase(deviceType));
		this.active_browsers = campaignInputOptions?.active_browsers?.map(activeBrowser => capitalCase(activeBrowser));
		this.operating_systems = campaignInputOptions?.operating_systems?.map(operatingSystem => capitalCase(operatingSystem));
		this.banner_sizes = campaignInputOptions?.banner_sizes;
		this.webapps_cid_segments = campaignInputOptions?.webapps_cid_segments;
		this.accounts = campaignInputOptions?.accounts.map(account => new AccountsModel(account));
		this.design_score_pickers = campaignInputOptions.design_score_pickers;
		this.format_score_pickers = campaignInputOptions.format_score_pickers;
	}

	getKeyGenerators(): string[] {
		return this.key_generators;
	}

	getIsLoading(): boolean {
		return this.isLoading;
	}

	setIsLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	getError(): Error | null {
		return this.error;
	}

	getAdvertisers(): AdvertiserModel[] {
		return this.advertisers;
	}

	getStrategies(): StrategyModel[] {
		return this.strategies;
	}

	getPretargetings(): PretargetingModel[] {
		return this.pretargetings;
	}

	getDeviceTypes(): string[] {
		return this.device_types;
	}

	getBrowsers(): string[] {
		return this.active_browsers;
	}

	getOperatingSystems(): string[] {
		return this.operating_systems;
	}
	getBannerSizes(): BannerSize[] {
		return this.banner_sizes;
	}
	getWebappsCidSegments(): ISegmentation[] {
		return this.webapps_cid_segments;
	}

	getAccounts(): AccountsModel[] {
		return this.accounts;
	}
}
