import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { FilterTypeEnum, SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { DataColumnConfig, DimensionsColumnConfig } from '../../../columns/columns';
import { StatusFilterDefinition, TextFilterDefinition } from '../../../table-filters/filters.definitions';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import routes from '../../../../../routes/index.routes';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { mapToTagStatus, Status, Statuses } from '../../../../../enums/status';
import {
	AuctionsColumnConfig,
	ClicksColumnConfig,
	ConversionsColumnConfig,
	ConversionValueColumnConfig,
	CostColumnConfig,
	CtrColumnConfig,
	CvrColumnConfig,
	GainColumnConfig,
	ImpressionsColumnConfig,
	OptCostColumnConfig,
	OptGainColumnConfig,
	RoasColumnConfig,
	WinRateColumnConfig,
	WinsColumnConfig,
} from '../../../columns/metrics.columns';
import { DateColumnConfig } from '../../../columns/adx-repr.columns';

export const testsTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxTestsReport',
		sort: [
			{
				column: 'test_name',
				sortOrder: SortOrderEnum.ASC,
			},
		],
		filters: [
			{
				column: 'test_status',
				filterType: FilterTypeEnum.EQUALS,
				value: [Statuses.Active],
				inverted: false,
			},
		],
		bypassCache: true,
	},
	tableState: {
		segment: 'test_group_name',
	},
	columns: [
		new DimensionsColumnConfig({
			header: 'Deal Name',
			name: 'test_name',
			accessor: 'test_name',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
			displaySegment: true,
			cell: ({ row }) => <TableLink toEdit={routes.tests.edit(row.getValue('test_id'))}>{row.getValue('test_name')}</TableLink>,
		}),

		new DimensionsColumnConfig({
			header: 'Status',
			name: 'test_status',
			accessor: 'test_status',
			availableFilters: new StatusFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => {
				const v = ((row.getValue('test_status') as string) || '').trim();
				return <Tag type={mapToTagStatus(v.toUpperCase() as Status)}>{v}</Tag>;
			},
		}),

		new DataColumnConfig({
			header: 'ID',
			name: 'test_id',
			accessor: 'test_id',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
		}),

		new DimensionsColumnConfig({
			header: 'Description',
			name: 'test_description',
			accessor: 'test_description',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
		}),

		new DimensionsColumnConfig({
			header: 'Percentage',
			name: 'test_percentage',
			accessor: 'test_percentage',
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => `${row.getValue('test_percentage')}%`,
		}),

		new AuctionsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new WinsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ClicksColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionsColumnConfig({
			name: 'sales',
			accessor: 'sales',
			visibility: ColumnVisibilityOptions.Visible,
			tooltip: 'The total number of sales that occurred in a segment',
		}),
		new CostColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),

		new ConversionValueColumnConfig({
			name: 'payout',
			accessor: 'payout',
			visibility: ColumnVisibilityOptions.Visible,
			tooltip: 'The amount of money the campaign was payed based on pixels reported',
		}),

		new WinRateColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new RoasColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DateColumnConfig({ visibility: ColumnVisibilityOptions.Hidden }),
		new ImpressionsColumnConfig({ visibility: ColumnVisibilityOptions.Hidden }),
		new CtrColumnConfig({ visibility: ColumnVisibilityOptions.Hidden }),
		new CvrColumnConfig({ accessor: 'cvr', visibility: ColumnVisibilityOptions.Hidden }),
		new GainColumnConfig({ visibility: ColumnVisibilityOptions.Hidden }),
		new OptGainColumnConfig({ visibility: ColumnVisibilityOptions.Hidden }),
		new OptCostColumnConfig({ visibility: ColumnVisibilityOptions.Hidden }),
	],
	includeChart: true,
	tableName: 'adxReprTests',
};
