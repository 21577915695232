import React, { CSSProperties, cloneElement, useEffect, useRef, useState } from 'react';
import { areSetsEqual } from '@monorepo/tools/src/lib/utils/set';
import { FormError } from '../../form/form-error/form-error';
import styles from './button-group.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';

interface IButtonGroupProps {
	onChange?: (value: Set<string>) => void;
	children: JSX.Element[];
	title?: string;
	multi?: boolean;
	defaultValues?: Set<string>; // ! For default values send new set with array of strings like this - new Set([...])
	defaultValue?: string;
	className?: string;
	titleClassName?: string;
	required?: boolean;
	error?: string;
	gap?: string;
	radio?: boolean;
	flexDirection?: string;
	alignItems?: string;
	disabled?: boolean;
	debugProps?: IDebugProps;
}

export { GButton } from './button/g-button';
export const ButtonGroup = (props: IButtonGroupProps) => {
	const {
		children,
		onChange,
		title,
		multi,
		defaultValue,
		defaultValues,
		required = false,
		error,
		gap,
		radio,
		flexDirection,
		alignItems,
		disabled,
		className,
		titleClassName,
	} = props;

	const [errorMsg, setErrorMsg] = useState<string | undefined>(error);
	const [activeValues, setActiveValues] = useState<Set<string>>(new Set());
	const prevDefaultValues = useRef<Set<string>>();
	const prevDefaultValue = useRef<string>();

	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	const isDefaultValueChanged = () => defaultValue !== prevDefaultValue?.current;

	const isDefaultValuesChanged = () =>
		(defaultValues && prevDefaultValues?.current && areSetsEqual(defaultValues, prevDefaultValues.current)) ||
		defaultValues !== prevDefaultValues?.current;

	useEffect(() => {
		if (defaultValue || defaultValues || isDefaultValueChanged() || isDefaultValuesChanged()) {
			setActiveValues(defaultValues || (defaultValue ? new Set([defaultValue]) : new Set()));
		}
		prevDefaultValues.current = defaultValues;
		prevDefaultValue.current = defaultValue;
	}, [defaultValues, defaultValue]);

	const _onChange = (value: string) => {
		let newSet = null;
		if (multi) {
			activeValues.has(value) ? activeValues.delete(value) : activeValues.add(value);
			newSet = new Set(activeValues);
		} else {
			newSet = new Set([value]);
		}
		setActiveValues(newSet);
		onChange?.(newSet);
	};

	const childrenWithExtraProp = React.Children.map(children, child => {
		if (child.type.displayName === 'GButton') {
			return cloneElement(child, {
				onChange: _onChange,
				activeValues,
				multi,
				error,
				radio,
				disabledGroup: disabled,
			});
		}
		return child;
	});

	return (
		<div className={`${styles.wrapper} ${errorMsg ? styles.errorWrapper : ''} ${className}`}>
			{title ? (
				<div className={`${styles.title} ${titleClassName}`}>
					{title} {required ? '*' : ''}
				</div>
			) : null}
			<div className={styles.group} style={{ gap, flexDirection, alignItems } as CSSProperties}>
				{childrenWithExtraProp}
			</div>
			<FormError msg={errorMsg} />
		</div>
	);
};
