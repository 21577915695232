import { ChangeEvent, Fragment, ReactNode, useEffect, useRef, useState } from 'react';

interface IUpload {
	children: ReactNode;
	onUpload: (file: File | FileList) => void | Promise<void>;
	isMultiple?: boolean;
	accept?: string;
}

export const Upload = (props: IUpload) => {
	const { children, onUpload, isMultiple, accept } = props;
	const [file, setFile] = useState<File | FileList>();
	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (onUpload && file) {
			onUpload(file);
		}
	}, [file]);

	const handleUploadClick = () => {
		if (!inputRef.current) {
			return;
		}

		inputRef.current.value = '';
		inputRef.current.click();
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}

		setFile(isMultiple ? e.target.files : e.target.files[0]);
	};

	return (
		<Fragment>
			<div onClick={handleUploadClick}>{children}</div>
			<input accept={accept} type="file" ref={inputRef} onChange={handleFileChange} style={{ display: 'none' }} />
		</Fragment>
	);
};
