import {
	CampaignGroupIdColumnConfig,
	CampaignGroupNameColumnConfig,
	CampaignGroupStatusColumnConfig,
} from '../../../columns/adx-repr.columns';
import {
	AuctionsColumnConfig,
	ClicksColumnConfig,
	ConversionsColumnConfig,
	ConversionValueColumnConfig,
	CostColumnConfig,
	CtrColumnConfig,
	GainColumnConfig,
	LpCvrColumnConfig,
	OptCostColumnConfig,
	OptGainColumnConfig,
	RoasColumnConfig,
	WinRateColumnConfig,
	WinsColumnConfig,
} from '../../../columns/metrics.columns';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { DimensionsColumnConfig } from '../../../columns/columns';

export const campaignGroupsAdvertiserTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCampaignsReport',
		sort: [
			{
				column: 'wins',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
	},
	tableState: {
		statusIcon: 'campaign_group_status',
	},
	columns: [
		new CampaignGroupNameColumnConfig({
			header: 'Name',
			visibility: ColumnVisibilityOptions.AlwaysVisible,
		}),
		new CampaignGroupStatusColumnConfig({
			header: 'Status',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new CampaignGroupIdColumnConfig({
			header: 'ID',
			visibility: ColumnVisibilityOptions.Visible,
		}),

		new DimensionsColumnConfig({
			name: 'dt',
			accessor: 'dt',
			header: 'Date',
			visibility: ColumnVisibilityOptions.Hidden,
		}),

		new AuctionsColumnConfig({ name: 'auctions_all', accessor: 'auctions_all', visibility: ColumnVisibilityOptions.Visible }),
		new WinsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),

		new ClicksColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionsColumnConfig({
			name: 'sales',
			accessor: 'sales',
			visibility: ColumnVisibilityOptions.Visible,
			tooltip: 'The total number of sales that occurred in a segment',
		}),
		new CostColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionValueColumnConfig({
			name: 'payout',
			accessor: 'payout',
			visibility: ColumnVisibilityOptions.Visible,
			tooltip: 'The amount of money the campaign was payed based on pixels reported',
		}),
		new WinRateColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new RoasColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CtrColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new LpCvrColumnConfig({ accessor: 'lp_cvr', visibility: ColumnVisibilityOptions.Visible }),

		new GainColumnConfig({ visibility: ColumnVisibilityOptions.Hidden }),
		new OptGainColumnConfig({ accessor: 'opt_gain', visibility: ColumnVisibilityOptions.Hidden }),
		new OptCostColumnConfig({ accessor: 'opt_cost', visibility: ColumnVisibilityOptions.Hidden }),
	],
	includeChart: true,
	tableName: 'adxReprCampaignGroupsPerformance-advertiser',
};
