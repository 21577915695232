import { useEffect, useState } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './g-button.module.scss';
import { Radio } from '../../../form/radio/radio';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

export interface IGButtonInject {
	isActive: boolean;
	multi: boolean;
}

interface IGButton {
	// will be added from group component
	activeValues?: Set<string>;
	onChange?: (value: string) => void;
	onClick?: (value?: string) => void;
	children?: (injectArgs: IGButtonInject) => JSX.Element[] | JSX.Element | string;
	disabled?: boolean;
	disabledTooltip?: string;
	multi?: boolean;
	error?: string;
	radio?: boolean;
	//

	value?: string;
	icon?: string;
	text?: string | JSX.Element;
	iconSize?: string;
	iconColor?: string;
	className?: string;
	textClassName?: string;
	debugProps?: IDebugProps;
}

export const GButton = (props: IGButton) => {
	const {
		children,
		onChange,
		onClick,
		value,
		icon,
		text,
		activeValues,
		disabled = false,
		disabledTooltip,
		iconSize,
		iconColor,
		multi,
		className,
		error,
		radio,
		textClassName,
		debugProps,
	} = props;

	const [isActive, setActive] = useState(value ? activeValues?.has(value) : false);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setActive(value ? activeValues?.has(value) : false);
	}, [activeValues]);

	const _onChange = () => {
		if (value) {
			setActive(!isActive);
			onChange?.(value);
		}
		onClick?.(value);
	};

	if (radio) {
		return (
			<Radio disabled={disabled} isActive={isActive} multi={multi} onChecked={() => _onChange()} debugProps={debugProps}>
				<span className={`${textClassName}`}>{text}</span>
			</Radio>
		);
	}

	return (
		<button
			title={disabledTooltip}
			disabled={disabled}
			className={`${styles.button} ${isActive ? styles.active : ''} ${className} ${error ? styles.error : ''} ${
				disabled ? styles.disabled : ''
			}`}
			onClick={() => _onChange()}
			{...generateDataAttrs(dataAttrs)}>
			{icon ? (
				<Icon isMFP={true} className={styles.icon} size={iconSize || ''} color={iconColor || ''}>
					{icon}
				</Icon>
			) : null}
			{text ? <div className={`${styles.text} ${textClassName}`}>{text}</div> : null}
			{children ? children({ isActive: Boolean(isActive), multi: Boolean(multi) }) : null}
		</button>
	);
};
GButton.displayName = 'GButton'; // for use in children.map loop
