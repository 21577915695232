import { Card } from '@monorepo/base/src/components/card/card';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import ReportTable from '../modules/components/report-table/table-component';
import { TableDefaultButton, TableDefaultButtonWrapper } from '../modules/components/report-table/table-elements/table-buttons';
import routes from '../modules/routes/index.routes';
import { CreativesBulkCSVCreate } from '../modules/components/creatives-bulk-create-csv/creatives-bulk-create-csv';
import { TableActionsBar } from '../modules/components/report-table/table-elements/table-actions';
import { CreativesRowActions } from '../modules/components/report-table/actions/creatives.action';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../modules/stores';
import { useTableStore } from '../modules/components/report-table/stores/use-table-store.hook';
import { useTableStoreMetrics } from '../modules/hooks/tools/use-repr-metrics';
import { Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/pagebar/pagebar';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page } from '@monorepo/base/src/components/page/page';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useParams } from 'react-router-dom';
import { useEffect, Fragment } from 'react';
import { ReportFilter, ReportFilterType } from '../modules/components/report-table/stores/data-stores/old-report.types';
import { observer } from 'mobx-react';
import { useList } from '../modules/hooks/tools/use-list';
import { CreativeModel } from '../modules/models/creative.model';

interface Props {
	hasChart?: boolean;
	hasCreate?: boolean;
	hasDetach?: boolean;
}

export const CreativesTable = observer(({ hasChart, hasCreate, hasDetach }: Props) => {
	const { campaignId, campaignGroupId, advertiserId, creativeGroupId } = useParams();

	const { creativeStore } = useStores<IAdminxStore>();
	const { creativesReportTable } = creativeStore;

	creativesReportTable.tableState.tableName = `adxReprCreativesPerformance-${campaignId || 'na'}-${advertiserId || 'na'}-${
		campaignGroupId || 'na'
	}-${creativeGroupId || 'na'}`;

	useTableStore({ reportTableStore: creativesReportTable });

	useList<CreativeModel>(creativeStore.getListStore(), {
		queryParams: {
			campaignId,
			campaignGroupId,
			advertiserId,
			creativeGroupId,
		} as Record<string, string>,
	});

	useEffect(() => {
		creativesReportTable.tableState.updateTableState({
			filters: [
				...creativesReportTable.tableState.filters.filter(
					f => !['campaign_id', 'advertiser_id', 'campaign_group_id', 'creative_group_id'].includes(f.column)
				),
				campaignId && {
					column: 'campaign_id',
					filterType: ReportFilterType.EQUALS,
					value: [campaignId],
				},
				campaignGroupId && {
					column: 'campaign_group_id',
					filterType: ReportFilterType.EQUALS,
					value: [campaignGroupId],
				},
				creativeGroupId && {
					column: 'creative_group_id',
					filterType: ReportFilterType.EQUALS,
					value: [creativeGroupId],
				},
				advertiserId && {
					column: 'advertiser_id',
					filterType: ReportFilterType.EQUALS,
					value: [advertiserId],
				},
			].filter(Boolean) as ReportFilter[],
		});
	}, [campaignId, advertiserId, campaignGroupId, creativeGroupId]);

	const chartProps = useTableStoreMetrics(creativesReportTable);

	return (
		<Page>
			<Pagebar debugProps={{ dataAttrs: [new DataAttribute('id', 'creatives_pagebar')] }}>
				<PagebarTitle>Creatives</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			{!!hasChart && (
				<Card full>
					<Chart isLoading={creativesReportTable.dataStore.getIsLoading()} {...chartProps} />
				</Card>
			)}

			<ReportTable tableStore={creativesReportTable} mandatoryOneMetricColumn showSegmentPicker>
				{!!hasCreate && (
					<Fragment>
						<TableDefaultButton
							to={creativeGroupId ? routes.creativeGroups.createCreative(creativeGroupId) : routes.creatives.create()}
						/>
						<TableDefaultButtonWrapper>
							<CreativesBulkCSVCreate />
						</TableDefaultButtonWrapper>
					</Fragment>
				)}

				<TableActionsBar>
					<CreativesRowActions hasDetach={hasDetach} />
				</TableActionsBar>
			</ReportTable>
		</Page>
	);
});
