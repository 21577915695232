import { observer } from 'mobx-react';
import { Outlet, useParams } from 'react-router-dom';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../../../modules/routes/index.routes';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { IAdminxStore } from '../../../../modules/stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { TableDefaultButton } from '../../../../modules/components/report-table/table-elements/table-buttons';
import { TableActionsBar } from '../../../../modules/components/report-table/table-elements/table-actions';
import { CampaignGroupRowActions } from '../../../../modules/components/report-table/actions/campaign-group.action';
import ReportTable from '../../../../modules/components/report-table/table-component';
import { useTableStore } from '../../../../modules/components/report-table/stores/use-table-store.hook';
import { useEffect } from 'react';
import { ReportFilter, ReportFilterType } from '../../../../modules/components/report-table/stores/data-stores/old-report.types';
import { useTableStoreMetrics } from '../../../../modules/hooks/tools/use-repr-metrics';

export const AdvertiserCampaignGroups = observer(() => {
	const { campaignGroupsStore } = useStores<IAdminxStore>();
	const { advertiserId } = useParams();

	const { campaignGroupsAdvertiserPerformance } = campaignGroupsStore;
	const chartProps = useTableStoreMetrics(campaignGroupsAdvertiserPerformance);

	useTableStore({ reportTableStore: campaignGroupsAdvertiserPerformance });

	useEffect(() => {
		campaignGroupsAdvertiserPerformance.tableState.updateTableState({
			filters: [
				...campaignGroupsAdvertiserPerformance.tableState.filters.filter(f => !['advertiser_id'].includes(f.column)),
				advertiserId && {
					column: 'advertiser_id',
					filterType: ReportFilterType.INCLUDE,
					value: [advertiserId],
				},
			].filter(Boolean) as ReportFilter[],
		});
	}, [advertiserId]);

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Campaign Groups</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			<Card full>
				<Chart isLoading={campaignGroupsAdvertiserPerformance.dataStore.getIsLoading()} {...chartProps} />
			</Card>

			<ReportTable tableStore={campaignGroupsAdvertiserPerformance}>
				<TableDefaultButton to={routes.advertisers.createCampaignGroup(advertiserId!)} />
				<TableActionsBar>
					<CampaignGroupRowActions />
				</TableActionsBar>
			</ReportTable>
			<Outlet />
		</Page>
	);
});
