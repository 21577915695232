import { Card } from '@monorepo/base/src/components/card/card';
import { BidderWhitelistType } from './bidder-whitelist-blacklist.consts';
import { observer } from 'mobx-react';
import { BidderWhitelistBlacklist } from './bidder-whitelist-blacklist';

export const BidderWhitelistBlacklistContainer = observer(() => {
	return (
		<Card title={'Black/White Lists'} id={'bidderBlackWhiteLists'}>
			<BidderWhitelistBlacklist type={BidderWhitelistType.Domains} />
			<BidderWhitelistBlacklist type={BidderWhitelistType.ISPs} />
			<BidderWhitelistBlacklist type={BidderWhitelistType.SUB_IDs} />
		</Card>
	);
});
