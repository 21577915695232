import { makeObservable, observable } from 'mobx';
import { BidderCampaignCrudStore } from './bidder-campaign-crud.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { BidderApi } from '../../apis/bidder.api';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { bidderCampaignsPerformanceTableConfig } from '../../components/report-table/configurations/adx-repr/bidder/bidder-campaigns.config';
import { NewAdminxReportTableStore } from '../../components/report-table/stores/table-stores/adx-repr-table.store';
import { BidderInputOptionsStore } from './bidder-input-options.store';
import { BidderWhitelistBlacklistStore } from './bidder-whitelist-blacklist.store';

export class BidderStore {
	crud = new BidderCampaignCrudStore();
	inputOptions = new BidderInputOptionsStore();
	whitelistBlacklist = new BidderWhitelistBlacklistStore();

	campaignsPerformance = new NewAdminxReportTableStore(bidderCampaignsPerformanceTableConfig);

	publishersList = new HttpStore<undefined, { success: boolean; publishers: { id: id; name: string }[] }>({
		httpFunc: BidderApi.getPublishers,
	});

	constructor() {
		makeObservable(this, {
			crud: observable,
		});
	}

	getCrud() {
		return this.crud;
	}

	getInputOptions() {
		return this.inputOptions;
	}
}
