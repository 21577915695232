import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { DimensionsColumnConfig } from '../../../columns/columns';
import routes from '../../../../../routes/index.routes';
import { AdvertiserEntityFilterDefinition } from '../../../table-filters/filters.definitions';
import { AdvertiserNameColumnConfig } from '../../../columns/adx-repr.columns';
import {
	AuctionsColumnConfig,
	ClicksColumnConfig,
	ConversionsColumnConfig,
	ConversionValueColumnConfig,
	CostColumnConfig,
	CtrColumnConfig,
	GainColumnConfig,
	LpCvrColumnConfig,
	OptCostColumnConfig,
	OptGainColumnConfig,
	RoasColumnConfig,
	WinRateColumnConfig,
	WinsColumnConfig,
} from '../../../columns/metrics.columns';
import { useParams } from 'react-router-dom';

export const creativeGroupsTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCreativesReport',
		sort: [
			{
				column: 'wins',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	columns: [
		new DimensionsColumnConfig({
			header: 'ID',
			accessor: 'creative_group_id',
			name: 'creative_group_id',
			visibility: ColumnVisibilityOptions.AlwaysVisible,
		}),

		new DimensionsColumnConfig({
			header: 'Design',
			accessor: 'creative_group_name',
			name: 'creative_group_name',
			visibility: ColumnVisibilityOptions.Visible,
			cell: ({ row }) => {
				const { advertiserId } = useParams();

				const creativeGroupId = row.getValue('creative_group_id') as string;

				if (!creativeGroupId) {
					return null;
				}

				return (
					<TableLink
						to={routes.creativeGroups.creatives(creativeGroupId)}
						toEdit={
							advertiserId
								? routes.advertisers.editCreativeGroup(advertiserId, creativeGroupId)
								: routes.creativeGroups.edit(creativeGroupId)
						}>
						{row.original.creative_group_name as string}
					</TableLink>
				);
			},
		}),

		new AdvertiserNameColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),

		new DimensionsColumnConfig({
			header: 'Advertiser ID',
			accessor: 'advertiser_id',
			name: 'advertiser_id',
			visibility: ColumnVisibilityOptions.Visible,
			availableFilters: new AdvertiserEntityFilterDefinition(),
		}),

		new AuctionsColumnConfig({ name: 'auctions_all', accessor: 'auctions_all', visibility: ColumnVisibilityOptions.Visible }),
		new WinsColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ClicksColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionsColumnConfig({
			name: 'sales',
			accessor: 'sales',
			visibility: ColumnVisibilityOptions.Visible,
			tooltip: 'The total number of sales that occurred in a segment',
		}),
		new CostColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new ConversionValueColumnConfig({
			name: 'payout',
			accessor: 'payout',
			visibility: ColumnVisibilityOptions.Visible,
			tooltip: 'The amount of money the campaign was payed based on pixels reported',
		}),
		new WinRateColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new RoasColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CtrColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new LpCvrColumnConfig({ accessor: 'lp_cvr', visibility: ColumnVisibilityOptions.Visible }),

		new GainColumnConfig({ visibility: ColumnVisibilityOptions.Hidden }),
		new OptGainColumnConfig({ accessor: 'opt_gain', visibility: ColumnVisibilityOptions.Hidden }),
		new OptCostColumnConfig({ accessor: 'opt_cost', visibility: ColumnVisibilityOptions.Hidden }),
	],
	includeChart: true,
	tableName: 'adxReprCreativeGroupsPerformance-na',
};
