export enum BidderWhitelistType {
	Domains = 'domains',
	ISPs = 'isps',
	SUB_IDs = 'sub_ids',
}

export enum UploadStatus {
	Success = 'success',
	Error = 'error',
}

export const BidderWhitelistData = {
	[BidderWhitelistType.Domains]: {
		title: 'Domains',
		apiUrls: {
			get: 'downloadCampaignDomains',
			post: 'uploadCampaignDomains',
		},
		extension: 'csv',
	},
	[BidderWhitelistType.ISPs]: {
		title: 'ISPs',
		apiUrls: {
			get: 'downloadCampaignISPs',
			post: 'uploadCampaignISPs',
		},
		extension: 'txt',
	},
	[BidderWhitelistType.SUB_IDs]: {
		title: 'Sub IDs',
		apiUrls: {
			get: 'downloadCampaignSubIds',
			post: 'uploadCampaignSubIds',
		},
		extension: 'csv',
	},
};
