import { Fragment } from 'react';
import { DropdownNew } from '@monorepo/base/src/components/dropdown-new/dropdown-new';
import styles from '../test-form.module.scss';
import { CampaignInputOptionsStore } from '../../../../stores/campaigns/campaign-input-options.store';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';

interface Props {
	inputOptions: CampaignInputOptionsStore;
	design?: string;
	format?: string;
	setDesign: (v?: string) => void;
	setFormat: (v?: string) => void;
}

export const ScorePicker = ({ inputOptions, design, format, setDesign, setFormat }: Props) => (
	<Fragment>
		<DropdownNew
			classNames={{
				parentSelectWrapper: styles.dropdownSelectWrapper,
			}}
			defaultValues={design ? [design] : []}
			multi={false}
			label="Design score picker"
			options={inputOptions.design_score_pickers.map(i => ({
				label: i,
				value: i,
			}))}
			onSelect={([v]) => setDesign(v as string)}
			isLabelOnBorder
		/>
		<Spacer />
		<DropdownNew
			classNames={{ parentSelectWrapper: styles.dropdownSelectWrapper }}
			defaultValues={format ? [format] : []}
			multi={false}
			label="Format score picker"
			options={inputOptions.format_score_pickers.map(i => ({
				label: i,
				value: i,
			}))}
			onSelect={([v]) => setFormat(v as string)}
			isLabelOnBorder
		/>
	</Fragment>
);
