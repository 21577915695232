import { Fragment } from 'react';
import { useTableContext } from '../table-component';
import { TableRawActionButton } from '../table-elements/table-actions';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../stores';
import routes from '../../../routes/index.routes';
import { DeleteModal } from '../../modals/delete-modal';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

export const CreativeGroupsRowActions = observer(() => {
	const { advertiserId } = useParams();
	const { tableStore } = useTableContext();
	const { creativeGroupsStore, modalsStore } = useStores<IAdminxStore>();
	const creativeGroupsCrudStore = creativeGroupsStore.getCrud();
	const { dispatchLog } = useLogs();

	const selectedIds = tableStore.getSelectedRows().map(r => r['creative_group_id']) as string[];
	const creativeGroupSingleAction = tableStore.getSelectedRows()[0];

	const deleteCreativeGroup = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Delete'}
					onAction={() => {
						const deleteId = selectedIds[0];
						creativeGroupsCrudStore
							.delete(deleteId)
							.then(() => {
								dispatchLog({
									msg: `Creative Group ${creativeGroupSingleAction['creative_group_name']}(${deleteId}) ${'Deleted'}`,
									type: DispatchLogsTypes.Success,
								});
								tableStore.fetchReport({}, false);
							})
							.catch(() => {
								dispatchLog({
									msg: `Delete ${creativeGroupSingleAction['creative_group_name']}(${deleteId}) Failed`,
									type: DispatchLogsTypes.Error,
								});
							});
					}}>
					<DeleteModal
						label={`Are you sure you want to delete ${creativeGroupSingleAction['creative_group_name']}(${selectedIds[0]})?`}
					/>
				</ActionModal>
			),
		});
	};

	return (
		<Fragment>
			<TableRawActionButton
				text={'Edit'}
				to={
					advertiserId
						? routes.advertisers.editCreativeGroup(advertiserId, selectedIds[0])
						: routes.creativeGroups.edit(selectedIds[0])
				}
				icon={'edit-02'}
				disabled={selectedIds.length !== 1}
			/>
			<TableRawActionButton text={'Delete'} onClick={deleteCreativeGroup} icon={'trash-02'} disabled={selectedIds.length !== 1} />
		</Fragment>
	);
});
