import { CreateCampaignGroups } from '../../pages/campaign-groups/create/create-campaign-group.page';
import { EditCampaignGroups } from '../../pages/campaign-groups/edit/edit-campaign-group.page';
import { CampaignGroups } from '../../pages/campaign-groups/index/campaign-groups.page';
import { CampaignGroupBidKeys } from '../../pages/campaign-groups/view/bid-keys/bid-keys.campaign-group';
import { CampaignGroupView } from '../../pages/campaign-groups/view/campaign-group-view.page';
import { CampaignGroupCampaigns } from '../../pages/campaign-groups/view/campaigns/campaigns.campaign-group';
import { CampaignGroupCreatives } from '../../pages/campaign-groups/view/creatives/creatives.campaign-group';
import { CampaignGroupDomains } from '../../pages/campaign-groups/view/domains/domains.campaign-group';
import { CampaignGroupPages } from '../../pages/campaign-groups/view/pages/pages.campaign-group';
import { CreateCampaign } from '../../pages/campaigns/create/create-campaign.page';
import routes from '../routes/index.routes';
import { Soon } from '@monorepo/base/src/components/soon/soon';

export const CampaignGroupsRouter = [
	{
		path: routes.campaignGroups.index(),
		element: <CampaignGroups />,
		children: [
			{
				path: routes.campaignGroups.edit(':campaignGroupId'),
				element: <EditCampaignGroups />,
			},
			{
				path: routes.campaignGroups.create(),
				element: <CreateCampaignGroups />,
			},
		],
	},
	{
		element: <CampaignGroupView />,
		children: [
			{
				path: routes.campaignGroups.view(':campaignGroupId'),
				element: <Soon />,
			},
			{
				path: routes.campaignGroups.campaigns(':campaignGroupId'),
				element: <CampaignGroupCampaigns />,
			},
			{
				path: routes.campaignGroups.createCampaign(':campaignGroupId'),
				element: <CreateCampaign parentRoute={routes.campaignGroups.createCampaign(':campaignGroupId')} />,
			},
			{
				path: routes.campaignGroups.creatives(':campaignGroupId'),
				// todo-new-tables replace with <CreativesTable hasChart />
				element: <CampaignGroupCreatives />,
			},
			{
				path: routes.campaignGroups.domains(':campaignGroupId'),
				element: <CampaignGroupDomains />,
			},
			{
				path: routes.campaignGroups.pages(':campaignGroupId'),
				element: <CampaignGroupPages />,
			},
			{
				path: routes.campaignGroups.bidKeys(':campaignGroupId'),
				element: <CampaignGroupBidKeys />,
			},
		],
	},
];
