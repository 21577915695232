import { useTableContext } from '../table-component';
import { useRef, useState } from 'react';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IndexLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { Select } from '@monorepo/base/src/components/select/select';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import cl from './segment-picker.module.scss';

const theme = getTheme();

const segments = [
	{ label: 'None', value: '' },
	{ label: 'Date', value: 'dt' },
	{ label: 'OS', value: 'os_name' },
	{ label: 'OS Version', value: 'os_version' },
	{ label: 'Browser', value: 'browser_name' },
	{ label: 'Browser Version', value: 'browser_version' },
	{ label: 'ISP', value: 'isp' },
	{ label: 'Connection Type', value: 'connection_type' },
	{ label: 'State', value: 'state' },
	{ label: 'Creative', value: 'creative_id' },
	{ label: 'Geo', value: 'geo' },
	{ label: 'Test Group', value: 'test_group_name' },
];

export const SegmentPicker = () => {
	const { tableStore } = useTableContext();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const btnRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(btnRef, () => setIsDropdownOpen(false));

	const handleChange = (segment: string) => {
		tableStore.onSegmentChange(segments.find(s => s.label === segment)!.value);
		setIsDropdownOpen(false);
	};

	return (
		<div ref={btnRef} className={cl.wrapper}>
			<IndexLinerButton>
				<SecondaryText
					className={`${cl.segmentBtn} ${tableStore.tableState.segment ? cl.active : cl.notActive}`}
					icon={'layers-three-01'}
					font={IconFonts.Outlined}
					iconColor={theme.actionLinerActionIconsColor}
					iconSize={'18px'}
					disabled={tableStore.tableState.isSpeedyModeOn}
					onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
					<div className={cl.segmentText}>Segment</div>
					{Boolean(tableStore.tableState.segment) && (
						<div className={cl.currentSegmentTitle}>{segments.find(s => s.value === tableStore.tableState.segment)?.label}</div>
					)}
				</SecondaryText>
			</IndexLinerButton>

			<Menu alignToRight isOpen={isDropdownOpen} className={cl.menuMinWidth}>
				<Select
					isSearch
					placeholder="Search Segment"
					values={segments
						// check if segment exists in columns list
						// .filter((s) => columnsMap.has(s.value))
						.map(s => s.label)}
					onClick={v => handleChange(v as string)}
				/>
			</Menu>
		</div>
	);
};
