import { observer } from 'mobx-react';
import { FC } from 'react';
import { Page } from '@monorepo/base/src/components/page/page';
import { Outlet } from 'react-router-dom';
import { CreativeGroupsTable } from '../../../components/creative-groups.table';

export const CreativeGroups: FC = observer(() => {
	return (
		<Page>
			<CreativeGroupsTable />

			<Outlet />
		</Page>
	);
});
