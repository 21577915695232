import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';

export const BidderWhitelistApi = {
	get: (path: string, campaignId: string): Promise<string> => {
		return ask.get(`${endpoints.bidder.whitelist(path)}?id=${campaignId}`, undefined, undefined, undefined, true);
	},

	post: (path: string, data: FormData) => {
		return ask.post(endpoints.bidder.whitelist(path), data);
	},
};
