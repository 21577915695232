import { makeAutoObservable, observable } from 'mobx';
import { CreativeGroupsCrudStore } from './creative-groups-crud.store';
import { IPerformanceOptions } from '../../hooks/apis/use-reports-list';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { filterPrototypesByProp } from '../../components/filters-menu/filters/index';
import { PerformanceResponse } from '../../models/performance.model';
import { PerformanceApi } from '../../apis/performance.api';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { NewAdminxReportTableStore } from '../../components/report-table/stores/table-stores/adx-repr-table.store';
import { creativeGroupsTableConfig } from '../../components/report-table/configurations/adx-repr/creative-groups/creative-groups.config';

export enum CreativeGroupFiltersStoreKeys {
	CreativeGroups = 'creativegroups',
	AdvertisersCreativeGroups = 'advertiserscreativegroups',
}

export class CreativeGroupsStore {
	crud = new CreativeGroupsCrudStore();
	list = new HttpStore<IPerformanceOptions, PerformanceResponse>({
		httpFunc: PerformanceApi.performance,
		model: PerformanceResponse,
	});

	metrics = new HttpStore<IPerformanceOptions, PerformanceResponse>({
		httpFunc: PerformanceApi.performance,
		model: PerformanceResponse,
	});

	settingsStore: SettingsStore;

	creativeGroupsReportTable = new NewAdminxReportTableStore(creativeGroupsTableConfig);

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this, {
			crud: observable,
			list: observable,
		});
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(CreativeGroupFiltersStoreKeys).map((key: CreativeGroupFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const campaignGroupFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, campaignGroupFilterByKey);
		});
	}

	getListStore() {
		return this.list;
	}

	getCrud() {
		return this.crud;
	}

	getMetrics() {
		return this.metrics;
	}
}
