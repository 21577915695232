import { Card } from '@monorepo/base/src/components/card/card';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import ReportTable from '../modules/components/report-table/table-component';
import { TableDefaultButton, TableDefaultButtonWrapper } from '../modules/components/report-table/table-elements/table-buttons';
import routes from '../modules/routes/index.routes';
import { CreativesBulkCSVCreate } from '../modules/components/creatives-bulk-create-csv/creatives-bulk-create-csv';
import { TableActionsBar } from '../modules/components/report-table/table-elements/table-actions';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../modules/stores';
import { useTableStore } from '../modules/components/report-table/stores/use-table-store.hook';
import { useTableStoreMetrics } from '../modules/hooks/tools/use-repr-metrics';
import { Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/pagebar/pagebar';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page } from '@monorepo/base/src/components/page/page';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useParams } from 'react-router-dom';
import { useEffect, Fragment } from 'react';
import { ReportFilter, ReportFilterType } from '../modules/components/report-table/stores/data-stores/old-report.types';
import { observer } from 'mobx-react';
import { CreativeGroupsRowActions } from '../modules/components/report-table/actions/creative-groups.action';

export const CreativeGroupsTable = observer(() => {
	const { advertiserId } = useParams();

	const { creativeGroupsStore } = useStores<IAdminxStore>();

	const { creativeGroupsReportTable } = creativeGroupsStore;
	creativeGroupsReportTable.tableState.tableName = `adxReprCreativeGroupsPerformance-${advertiserId || 'na'}`;

	useTableStore({ reportTableStore: creativeGroupsReportTable });

	useEffect(() => {
		creativeGroupsReportTable.tableState.updateTableState({
			filters: [
				...creativeGroupsReportTable.tableState.filters.filter(f => !['advertiser_id'].includes(f.column)),
				advertiserId && {
					column: 'advertiser_id',
					filterType: ReportFilterType.EQUALS,
					value: [advertiserId],
				},
			].filter(Boolean) as ReportFilter[],
		});
	}, [advertiserId]);

	const chartProps = useTableStoreMetrics(creativeGroupsReportTable);

	return (
		<Page>
			<Pagebar debugProps={{ dataAttrs: [new DataAttribute('id', 'creatives_pagebar')] }}>
				<PagebarTitle>Creative Groups</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			<Card full>
				<Chart isLoading={creativeGroupsReportTable.dataStore.getIsLoading()} {...chartProps} />
			</Card>

			<ReportTable tableStore={creativeGroupsReportTable} showSegmentPicker>
				<Fragment>
					<TableDefaultButton
						to={advertiserId ? routes.advertisers.createCreativeGroup(advertiserId) : routes.creativeGroups.create()}
					/>
					<TableDefaultButtonWrapper>
						<CreativesBulkCSVCreate />
					</TableDefaultButtonWrapper>
				</Fragment>

				<TableActionsBar>
					<CreativeGroupsRowActions />
				</TableActionsBar>
			</ReportTable>
		</Page>
	);
});
